<template>
  <div>
    <v-card id="card-custom" class="px-2 py-4 rounded-lg">
      <v-card-actions>
        <p class="headline-color px-2">Daftar Pendidikan</p>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="getToPage('data-pendidikan-level')"
            >
              <v-icon>settings</v-icon>
            </v-btn>
          </template>
          <span>Daftar Tingkatan (Level) Pendidikan</span>
        </v-tooltip>
      </v-card-actions>
      <v-divider />
      <v-row class="mx-2">
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
          <v-btn
            text
            class="text-capitalize caption headline-color"
            color="primary"
            @click="
              visible = true;
              isEdit = false;
            "
            >Buat Pendidikan<v-icon class="ml-2" color="primary"
              >add_circle</v-icon
            ></v-btn
          >
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
          <v-autocomplete
            placeholder="- All - "
            v-model="level"
            :items="levels"
            :loading="loadingLevel"
            outlined
            dense
            hide-details
            clearable
            item-text="deskripsi_level"
            item-value="pend_level_id"
            class="mx-2"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
          <v-text-field
            v-model="search"
            placeholder="Cari"
            outlined
            dense
            hide-details
            prepend-inner-icon="search"
            class="mx-2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        id="table-custom"
        :headers="headers"
        :items="pendidikans"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pagination.totalItem"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems
        }"
        loading-text="Loading... Please wait"
      >
        <template v-slot:[`item.nama_pend`]="{ item }">
          <span class="hover-primary" @click="$emit('on-select', item)">{{
            item.nama_pend
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small color="primary" @click="handleEdit(item)"
            ><v-icon small>edit</v-icon></v-btn
          >
          <v-btn icon small color="primary" @click="handleDelete(item)"
            ><v-icon small>delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="visible" persistent max-width="400">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          Buat Pendidikan
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Kode</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.kode_pend"
                :disabled="isEdit"
                outlined
                dense
                :rules="[v => !!v || 'Kode harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Pendidikan</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-text-field
                v-model="form.nama_pend"
                outlined
                dense
                :rules="[v => !!v || 'Pendidikan harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tingkatan</v-subheader
              >
            </v-col>
            <v-col cols="7" class="py-1">
              <v-autocomplete
                v-model="form.pend_level_id"
                :loading="loadingLevel"
                :items="levels"
                :disabled="isEdit"
                outlined
                dense
                item-text="deskripsi_level"
                item-value="pend_level_id"
                :rules="[v => !!v || 'Tingkat harus diisi']"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  data() {
    return {
      visible: false,
      valid: false,
      isEdit: false,
      loading: false,
      loadingLevel: false,
      formLoading: false,
      level: null,
      levels: [],
      search: null,
      headers: [
        {
          text: "Kode",
          value: "kode_pend",
          sortable: false,
          align: "center"
        },
        {
          text: "Pendidikan",
          value: "nama_pend",
          sortable: false,
          align: "center"
        },
        {
          text: "Tingkat",
          value: "pend_level",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      pendidikans: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["pend_level"],
        sortDesc: [false]
      },
      dayDiffTry: 0,
      dayDiff: 0,
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        kode_pend: null,
        nama_pend: null,
        pend_level_id: null,
        gelar_pend: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getPendidikanList();
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getPendidikanList);
    },
    visible(val) {
      if (!val) {
        this.dayDiff = 0;
        this.dayDiffTry = 0;
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    paginationProperty() {
      return [this.search, this.level].join();
    }
  },
  methods: {
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.getDetailPendidikan(item.kode_pend);
    },
    handleDelete(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              kode_pend: item.kode_pend
            };
            this.deletePendidikan(payload);
          }
        }
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("kode_pend", this.form.kode_pend);
        formData.append("nama_pend", this.form.nama_pend);
        formData.append("pend_level_id", this.form.pend_level_id);
        this.savePendidikan(formData);
      }
    },
    // Service
    async getPendidikanLevelLOV() {
      try {
        this.loadingLevel = true;
        await DataUtamaService.getPendidikanLevelLOV({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.levels = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingLevel = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getPendidikanList() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await DataUtamaService.getPendidikanList({
          filter: {
            search: this.search,
            pend_level_id: this.level
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let pendidikans = list;
              pendidikans.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.pendidikans = pendidikans;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async savePendidikan(data) {
      try {
        this.formLoading = true;
        await DataUtamaService.savePendidikan(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getPendidikanList();
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailPendidikan(data) {
      try {
        this.loading = true;
        await DataUtamaService.getPendidikanDetail(data)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.$_.merge(this.form, data);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deletePendidikan(payload) {
      try {
        this.formLoading = true;
        await DataUtamaService.deletePendidikan(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.getPendidikanList();
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getPendidikanLevelLOV();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
